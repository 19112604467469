<template>
  <div class="chart-list">
    <div class="container">
      <relation-title
        title="关系图谱列表"
        btnTitle="添加关系图谱"
        :showBtn="true"
        @titleBtnClick="addBtn()"
      />
      <div class="table mt24">
        <el-table
          :data="relationList"
          style="width: 100%;"
          height="100%"
          header-cell-class-name="proj_table_header"
        >
          <el-table-column prop="code" label="编号" header-align="center" align="center" 
            width="180">
          </el-table-column>
          <el-table-column prop="name" label="关系图谱名称" header-align="center" align="center" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="desc" label="关系图谱地址" header-align="center" align="center" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div v-for="item in scope.row.addrs" :key="item">{{ item }}</div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            header-align="center"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <el-button @click="editRow(scope.row)" type="text">编辑</el-button>
              <el-button @click="gotoChartList(scope.row)" type="text">关系图谱地址</el-button>
              <el-button @click="delRow(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          class="proj_table_pagination"
          :background="true"
          layout="prev, pager, next,total,jumper"
          :total="total"
          :page-size="pageSize"
          :current-page="pageNo"
          @current-change="currentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="isAdd ? '新增关系图谱' : '编辑关系图谱'"
      :visible.sync="dialogVisible"
      :center="true"
      :destroy-on-close="true"
      width="480px"
      custom-class="proj_dialog"
    >
      <div>
        <el-form label-position="top" label-width="80px" :model="form">
          <el-form-item label="编号" v-if="isAdd">
            <el-input value="自动生成" disabled></el-input>
          </el-form-item>
          <el-form-item label="关系图谱名称">
            <el-input v-model="form.name" placeholder="限制12字以内" :maxlength="12"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RelationTitle from "./components/RelationTitle";
import { getProjectDetail, addRel, updetaRel, getRelCode,delRel } from "@/api/relation";
export default {
  name: "chartlist",
  components: {
    RelationTitle,
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        prjId: "",
        name: "",
        code: "",
      },
      isAdd: true,
      relationList: [],
      pageNo: 1,
      pageSize: 15,
      total: 0,
    };
  },
  mounted() {
    this.form.prjId = this.$route.query.projId;
    this.getProjectDetail();
  },
  methods: {
    getProjectDetail() {
      getProjectDetail(this.form.prjId).then((res) => {
        this.relationList = res.data.data.relationProjectVos;
      });
    },
    currentChange(val) {
      this.pageNo = val;
      this.getProjectDetail();
    },
    gotoChartList(item) {
      this.$router.push({ name: "relationChart", query: { relId: item.id } });
    },
    addBtn() {
      this.isAdd = true;
      this.dialogVisible = true;

      getRelCode(this.form.prjId).then((res) => {
        this.form.code = res.data.data;
      });
    },
    editRow(item) {
      this.form.id = item.id;
      this.form.name = item.name;
      this.form.desc = item.desc;
      this.isAdd = false;
      this.dialogVisible = true;
    },
    delRow(item){
      
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRel(item.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            this.getProjectDetail();
          }
        });
      });
    },
    submit() {
      if (this.isAdd) {
        addRel(this.form).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("图谱新增成功");
          }
          this.pageNo = 1;
          this.getProjectDetail();
          this.dialogVisible = false;
        });
      } else {
        updetaRel(this.form).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("图谱修改成功");
          }
          this.pageNo = 1;
          this.getProjectDetail();
          this.dialogVisible = false;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-list {
  background-color: #f8f8f8;
  box-sizing: border-box;
  flex: 1;
  padding: 32px 24px 24px 24px;
  .container {
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .table {
      flex: 1;
      background-color: #fff;
      box-shadow: 0px 4px 8px 0px rgba(240, 240, 240, 0.5);
      border-radius: 4px;
    }
    .pagination {
      height: 157px;
      background-color: #fff;
      padding-left: 80px;
      padding-top: 44px;
      box-sizing: border-box;
    }
  }
}
</style>
