import request from "@/api/http";

/**
 * 获取项目列表
 *
 * @params {pageNo} 页码
 * @params {pageSize} 每页条数
 */
export const getProjectList = (pageNo, pageSize) => {
  return request({
    url: "/api/system/prj/pagelist",
    method: "post",
    data: {
      pageNo,
      pageSize,
    },
  });
};

/**
 * 获取项目详情
 *
 * @params {id} 项目id
 */
export const getProjectDetail = (id) => {
  return request({
    url: "/api/system/prj/detail",
    method: "post",
    params: {
      id,
    },
  });
};

/**
 * 获取项目编码
 *
 * @params {id} 项目id
 */
 export const getProjectCode = () => {
  return request({
    url: "/api/system/prj/code",
    method: "post"
  });
};

/**
 * 新增项目
 *
 * @params {code} 项目编码
 * @params {desc} 项目描述
 * @params {name} 项目名称
 */
export const addProject = (data) => {
  return request({
    url: "/api/system/prj/add",
    method: "post",
    data
  });
};
/**
 * 更新项目
 *
 * @params {id} 项目id
 * @params {desc} 项目描述
 * @params {name} 项目名称
 */
export const updetaProject = (data) => {
  return request({
    url: "/api/system/prj/update",
    method: "post",
    data
  });
};
/**
 * 删除项目
 *
 * @params {id} 项目id
 */
export const delPrj = (id) => {
  return request({
    url: "/api/system/prj/delete",
    method: "post",
    params:{
      id
    }
  });
};

/**
 * 获取关系图谱详情
 *
 * @params {id} 图谱id
 */
export const getRelDetail = (id) => {
  return request({
    url: "/api/system/rel/detail",
    method: "post",
    params: {
      id,
    },
  });
};
/**
 * 获取图谱编码
 *
 * @params {prjId} 项目id
 */
 export const getRelCode = (prjId) => {
  return request({
    url: "/api/system/rel/code",
    method: "post",
    params:{
      prjId
    }
  });
};
/**
 * 新增关系图谱
 *
 * @params {code} 图谱code
 * @params {name} 图谱名称
 * @params {projId} 项目id
 */
export const addRel = (data) => {
  return request({
    url: "/api/system/rel/add",
    method: "post",
    data
  });
};
/**
 * 更新图谱
 *
 * @params {id} 图谱id
 * @params {name} 图谱名称
 */
export const updetaRel = (data) => {
  return request({
    url: "/api/system/rel/update",
    method: "post",
    data
  });
};
/**
 * 删除图谱
 *
 * @params {id} 图谱id
 */
export const delRel = (id) => {
  return request({
    url: "/api/system/rel/delete",
    method: "post",
    params:{
      id
    }
  });
};

/**
 * 获取图谱代币列表
 *
 * @params {id} 图谱id
 */
export const getKeyList = () => {
  return request({
    url: "/api/system/chaintoken/tokens",
  });
};

/**
 * 地址分析
 */
export const analyseRel = () => {
  return request({
    url: "/api/system/rel/analyse",
    method: "post",
    data: {
      id: 11,
      code: "0000",
      name: "0x3a0b4627979ff8efb6ca90c21eb2130cd952f0da",
      createTime: "2022-04-26 02:53:57",
      updateTime: "2022-04-26 12:51:01",
      tokenCode: "ETH",
      addrs: ["0x9eee6862b78fb6f9627d7d5a908d2114814fcecd"],
      minAmount: null,
      maxAmount: null,
      startDate: -1893600000,
      endDate: 1670608800000,
      vitalClue: false,
      to: true,
      label: false,
      exchange: true,
      from: false,
      maxNextTxs: null,
      layer: 3,
      addrList: ["0x9eee6862b78fb6f9627d7d5a908d2114814fcecd"],
      validateDate: ["1969-12-10T02:00:00.000Z", "2022-12-09T18:00:00.000Z"],
    },
  });
};

/**
 * 初始化图谱接口
 * 
 * @params {addrList } 地址编码数组
 * @params {startDate } 开始时间
 * @params {endDate } 结束时间
 * @params {exchange } 是否只看实体标签
 * @params {from } 是否显示流入
 * @params {to } 是否显示流出
 * @params {layer  } 显示层数
 * @params {maxAmount  } 最大金额
 * @params {minAmount  } 最小金额
 * @params {tokenCode  } 币种
 * @params {maxNextTxs  } 节点展开数
 * 
 */
export const relationInit = (data) => {
  return request({
    url: `/api/biz${data.tokenCode.toLowerCase()}/rel/relationInit`,
    method: "post",
    data
  });
};

/**
 * 获取当前地址的关系
 * 
 * @params {addr } 地址编码
 * @params {limit } 查询个数
 * @params {offset } 从第N个开始查询
 * @params {tokenType } 主链code
 * @params {type  } 0-流入 1-流出 1-all
 */
export const getAddrRelation = (addressSet, type, offset, limit = 10, tokenType = "ETH") => {
  return request({
    url: `/api/biz${tokenType.toLowerCase()}/addr/relation`,
    method: "post",
    data: {
      addressSet,
      type,
      offset,
      limit,
      tokenType,
    },
  });
};

/**
 * 获取当前地址的所有交易数量
 * 
 * @params {addr } 地址编码
 * @params {tokenType } 主链code
 */
export const getRelationCount = (addressSet, tokenType = "ETH") => {
  return request({
    url: `/api/biz${tokenType.toLowerCase()}/addr/relationCount`,
    method: "post",
    data: {
      addressSet,
      tokenType,
    },
  });
};

/**
 * 获取当前地址的所有关系
 * 
 * @params {addr } 地址编码
 * @params {tokenType } 主链code
 */
export const getRelationAll = (addressSet, tokenType = "ETH") => {
  return request({
    url: `/api/biz${tokenType.toLowerCase()}/addr/relationAll`,
    method: "post",
    data: {
      addressSet,
      tokenType,
    },
  });
};


/**
 * 获取节点详情
 * 
 * @params {addr } 地址编码
 * @params {token } 主链code
 */
export const getAddrInfo = (addr, token) => {
  return request({
    url: `/api/biz${token.toLowerCase()}/addr/info`,
    method: "get",
    params: {
      addr,
      token,
    },
  });
};

/**
 * 获取连线交易详情
 * 
 * @params {from } 交易流入地址
 * @params {to } 交易流出地址
 * @params {pageNo } 页码
 * @params {pageSize } 页数
 * @params {tokenCode } 主链code
 */
export const getMultiTxnDetail = (from, to, tokenCode, pageNo, pageSize,startDate,endDate,hash) => {
  return request({
    url: `/api/biz${tokenCode.toLowerCase()}/tx/list`,
    method: "post",
    data: {
      from,
      to,
      tokenCode,
      pageNo,
      pageSize,
      startDate,
      endDate,
      hash
    },
  });
};




/**
 * 获取标签列表
 * 
 */
export const getLabelList = (addr, token) => {
  return request({
    url: "/api/system/label/labels",
    method: "post",
  });
};
/**
 * 增加标签
 * 
 * @params {token } 主链code
 */
export const addLabel = (name) => {
  return request({
    url: "/api/system/label/add",
    method: "post",
    data: {
      name
    }
  });
};

/**
 * 获取用户保存的图谱
 * 
 * @params {relId } 图谱id
 * @params {tokenCode } 主币种
 */
export const getSavedRelation = (relId,tokenCode) => {
  return request({
    url: `/api/biz${tokenCode.toLowerCase()}/rel/relationGet`,
    method: "post",
    params: {
      relId,tokenCode
    }
  });
};

/**
 * 获取用户保存的图谱
 * 
 * @params 参数格式参考初始化返回值
 */
 export const saveRelation = (data) => {
  return request({
    url: `/api/biz${data.tokenCode.toLowerCase()}/rel/relationSave`,
    method: "post",
    data
  });
};

/**
 * 下载图谱
 * 
 * @params {addr } 地址编码
 * @params {tokenType } 主链code
 */
export const downExchange = ( chain, vertices,edges) => {
  return request({
    url: `/api/system/txs/down/exchange`,
    method: "post",
    data: {
      chain,
      edges,
      vertices
    },
  });
};
