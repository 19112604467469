<template>
  <div>
    <div class="relation-title">
      <i class="el-icon-arrow-left" @click="goBack" v-if="isBack"></i>
      <div class="relation-title-name">{{ title }}</div>
      <div class="relation-title-btn">
        <!-- <el-button
          v-if="showCollapse"
          type="info"
          icon="el-icon-arrow-up"
          circle
          @click="collapseClick"
        ></el-button> -->
        <div class="icon">+</div>
        <div class="plus" @click="btnClick">{{ btnTitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "relation-title",
  props: {
    title: {
      type: String,
      default: "",
    },
    btnTitle: {
      type: String,
      default: "按钮",
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    showCollapse: {
      type: Boolean,
      default: false,
    },
    isBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    console.log("进来了");
  },
  methods: {
    btnClick() {
      this.$emit("titleBtnClick");
    },
    collapseClick() {
      this.$emit("collapseClick");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.relation-title {
  font-size: 18px;
  &-name {
    height: 32px;
    line-height: 32px;
    font-size: 24px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #202038;
    flex: 1;
  }
  &-btn {
    width: 204px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    margin-top: 23px;
    .icon{
      flex: 1;
      font-size: 24px;
      height: 24px;
      line-height: 24px;
      margin-left: 25px;
    }
    .plus {
      width: 113px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #2e43eb;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      margin-right: 4px;
    }
  }
  /deep/.el-icon-arrow-left {
    line-height: 32px;
  }
}
</style>
